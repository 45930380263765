import appConfig from 'configs/app.config'  
const { apiPrefixServer } = appConfig

//funcion para iniciar sesion
export function getLogin(companyid,username,password) {
    const url = `${apiPrefixServer}/get-login/${companyid}/${username}/${password}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "applicaction/json"
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}


//funcion para tomar los usuarios
export function getUsers(companyid) {
    const url = `${apiPrefixServer}/get-users/${companyid}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "applicaction/json"
        }
    };

    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function getUser(id) {
    const url = `${apiPrefixServer}/get-user/${id}`;
    const params = {
        method: "GET",
        headers: {
            "Content-Type": "applicaction/json"
        }
    };
    return fetch(url, params)
        .then(response => {
            return response.json();
        })
        .then(result => {
            return result;
        })
        .catch(err => {
            return err.message;
        });
}

export function createUser(data){
    const url=`${apiPrefixServer}/post-user`;
    const params={
        method:"POST",
        body:JSON.stringify(data),
        headers:{
            "Content-Type":"application/json"
        }
    };
    return fetch(url,params)
    .then(response=>{
        return response.json();
    })
    .then(result=>{
        return result;
    })
    .catch(err=>{
        return err.message;
    });
}


export function updateUser(data, id){ 
    console.log("Aqui actualizando")
    console.log(data)
    const url=`${apiPrefixServer}/update-user/${id}`;
    const params={
        method:"PUT",
        headers:{
            "Content-Type": "application/json",
                },
        body: JSON.stringify(data)
    };
    return fetch(url,params)
    .then(response=>{
        return response.json();
    })
    .then(result=>{
        return result;
    })
    .catch(err=>{
        return err.message;
    });
}

export function deleteUser(id){
    const url=`${apiPrefixServer}/delete-user/${id}`;
    const params ={
        method: "DELETE",
        headers:{
            "Content-Type": "application/json",
        }
    }
    return fetch(url,params)
    .then(response => {
        return response.json();
    })
    .then(result=>{
        return result.message;
    })
    .catch(err=>{
        return err.message;
    })
}