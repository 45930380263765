const appConfig = {
    apiPrefix: '/api',
    apiReportsPrefix: '/api',
    apiPrefixServer: 'https://server.qui-max.com/api',
    ///apiPrefixServer: 'http://localhost:3050/api',  
    apiReportsServer: 'https://reports.syslogistica.com/api', //https://localhost:44351/api, 
    authenticatedEntryPath: '/pages/welcome' ,
    AZURE_STORAGE_CONNECTION_STRING: "DefaultEndpointsProtocol=https;AccountName=quimax;AccountKey=TtarInGD2LDk7CdnHe5c/kA4joAqR9ivSG0t9i+mV4Loe8GjpS6OfvtmaIXZQ5TMlY2Fb01JJnIG+AStbSPk+w==;EndpointSuffix=core.windows.net",
    AZURE_containername:"quimaxstore",
    storageAccountName: "quimax",
    sasToken: "sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2030-05-31T00:51:02Z&st=2024-05-30T16:51:02Z&spr=https&sig=%2B3vh6mSp7tHyJZ92no5VCZynq50t1P6OW1pYa5Qxox0%3D",
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true
}

export default appConfig